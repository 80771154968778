
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {
  addFacility,
  deleteFacility,
  getFacilities,
  updateFacility,
} from "@/api/facility.api";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";

export interface Data {
  items: [];
  selectedItem?: { id: string; effectiveDate: Date };
  createNewMode: boolean;
}
export default defineComponent({
  name: "FacilityCRUDPage",
  components: {},
  setup() {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      selectedItem: undefined,
      createNewMode: true,
    });

    data.selectedItem = {
      id: "",
      effectiveDate: new Date(),
    };

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Facilities");
      await getAll();
    });

    async function switchMode() {
      data.createNewMode = true;
      data.selectedItem = {
        id: "",
        effectiveDate: new Date(),
      };
    }

    async function getAll() {
      data.items = await getFacilities();
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addFacility(data.selectedItem);
        await getAll();
        data.createNewMode = false;
      }
    }

    function selectItem(item) {
      data.selectedItem = item;
      data.createNewMode = false;
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        await updateFacility(data.selectedItem);
      }
    }

    async function deleteItem() {
      await deleteFacility(data.selectedItem);
      await getAll();
    }

    const providerRules = {
      selectedItem: {
        address: { required:helpers.withMessage("Required", required) },
        address2: { required:helpers.withMessage("Required", required) },
        city: { required:helpers.withMessage("Required", required) },
        state: { required:helpers.withMessage("Required", required) },
        zip: { required:helpers.withMessage("Required", required) },
        country: { required:helpers.withMessage("Required", required) },
        taxId: { required:helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(providerRules, data as never);

    return {
      organizationId,
      data,
      addItem,
      selectItem,
      updateItem,
      deleteItem,
      switchMode,
      v$,
    };
  },
});
